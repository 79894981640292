<template>
 <div>
  <b-card no-body>
    <b-tabs card small>
      <b-tab v-for="tab in tabs" :key="tab.title" :title="tab.title">
        <slot :name="tab.slot"></slot>
      </b-tab>
    </b-tabs>
  </b-card>
</div>   
</template>


<script>

export default {
    name: 'TabsContent',
    data () {
        return {
            
        }
    },
    props: ['tabs']
}
</script>
<style lang="scss" scoped>
.tab-content>.active{
  overflow: scroll;
}
</style>
